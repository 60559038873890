import styled from 'styled-components';

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';

import theme, { mobileFirstMedia as media } from '../../config/theme';

// import theme, { mobileFirstMedia as media } from '../../config/theme ';

export const CustomInput = withStyles({
  root: {
    margin: 0,
    position: 'relative',
    '& label.Mui-focused': {
      color: theme.colors.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
      },
      '&:hover fieldset': {
        borderColor: theme.colors.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.primary,
      },
    },
    // '& .MuiInputBase-root': {
    //   // '& .MuiFormHelperText-contained': {
    //   //   marginLeft: '0px',
    //   // },
    //   position: 'relative',
    // },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -10,
      right: -5,
      padding: 4,
      background: 'white',
      border: '1px solid red',
    },
  },
})(TextField);

export const Form = styled.form`
  max-width: 100%;
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: 1fr;

  @media (${media.xs}) {
    grid-template-columns: 150px 1fr;
    /* grid-template-columns: 2fr 1fr;
    grid-template-columns: 2fr 1fr; */
    /* grid-template-rows: repeat(3, auto); */
  }
`;
