import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';

import Button from '../button/button';
import { Form, CustomInput } from './styles';
import { mobileFirstMedia as media } from '../../config/theme';

const ContactForm = ({
  initialValues,
  formLayout,
  validationSchema,
  onSubmit,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, { resetForm, setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
      resetForm();
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      isSubmitting,
    }) => (
      <Form
        onSubmit={handleSubmit}
        name="Kontakt"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        <input type="hidden" name="form-name" value="Kontakt" />

        {Object.entries(formLayout).map(([key, value]) => (
          <React.Fragment key={key}>
            <InputLabel
              required={value.required}
              css={`
                grid-column: 1 / 2;
                margin-bottom: -20px;

                @media (${media.xs}) {
                  grid-column: 1 / 2;
                  margin-top: 13px;
                }
              `}
            >
              {value.label}
            </InputLabel>

            <CustomInput
              type={value.type}
              name={key}
              id={key}
              variant="outlined"
              margin="dense"
              onChange={handleChange}
              value={values[key]}
              error={errors[key] && touched[key]}
              helperText={(errors[key] && touched[key] && errors[key]) || ''}
              css={`
                grid-column: 1 / 2;

                @media (${media.xs}) {
                  grid-column: 2 / 3;
                }
              `}
              {...value.props}
            />
          </React.Fragment>
        ))}

        <Button
          type="submit"
          disabled={isSubmitting}
          css={`
            grid-column: 1 / 2;

            @media (${media.xs}) {
              grid-column: 2 / 3;
              justify-self: end;
            }
          `}
        >
          Wyślij wiadomość
        </Button>
      </Form>
    )}
  </Formik>
);

ContactForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formLayout: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
