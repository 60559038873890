import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import * as Yup from 'yup';

import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import SEO from '../components/seo';
import Banner from '../components/banner/banner';
import Heading from '../components/heading/heading';
import Button from '../components/button/button';
import Typography from '../components/typography/typography';
import Container from '../components/container/container';
import Footer from '../components/footer/footer';
import ContactForm from '../components/contactForm/contactForm';
import { mobileFirstMedia as media } from '../config/theme';
import {
  NavMenu,
  NavItem as NavItemDefault,
} from '../components/header/styles';

const AlertBanner = styled.div`
  padding: 1em;
  margin-top: -20px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 1.5em;
  border-left: 3px solid ${({ theme }) => theme.colors.primary};
  background-color: hsl(0, 0%, 96%);

  @media (${media.s}) {
    margin-left: 110px;
    margin-right: 110px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 40% 1fr 1fr;
  }

  @media (${media.l}) {
    grid-template-columns: 1fr 1fr 20% 20%;
  }
`;

const Subgrid = styled.div`
  align-self: flex-start;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 4;
    grid-row-start: 1;
    grid-row-end: auto;
    grid-row: 1 / span 4;
  }

  @media (${media.l}) {
    grid-column: 3 / 5;
  }
`;

const NavItem = styled(NavItemDefault)`
  margin: 0.4em 10px;
  height: auto;
  font-size: 0.85em;
`;

const HeadingArea = styled.div`
  margin-bottom: 10px;
  grid-column: 1 / 3;
`;

const AddressArea = styled.div`
  grid-column: 1 / 3;

  @media (${media.s}) {
    grid-column: 1 / 2;
  }
`;

const SecretaryArea = styled.div`
  grid-column: 1 / 3;

  @media (${media.s}) {
    grid-column: 2 / 3;
  }
`;

const PhoneArea = styled.div`
  grid-column: 1 / 3;

  @media (${media.s}) {
    grid-column: 1 / 2;
  }
`;

const CommerceArea = styled.div`
  grid-column: 1 / 3;

  @media (${media.s}) {
    grid-column: 2 / 3;
  }
`;

const FormArea = styled.div`
  margin-top: 20px;
  grid-column: 1 / 3;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Image1Area = styled.div`
  display: none;

  @media (${media.m}) {
    display: block;
    grid-column: 1 / 2;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);
  }

  @media (${media.l}) {
    height: 350px;
    width: 150%;
  }
`;

const Image1 = styled(Image)`
  clip-path: polygon(
    var(--cropSize) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 var(--cropSize)
  );
`;

const Image2Area = styled.div`
  display: none;

  @media (${media.m}) {
    display: block;
    grid-column: 1 / 2;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);
  }

  @media (${media.l}) {
    margin-top: 150px;
    margin-bottom: calc(var(--cropSize) * 0.7);
    grid-column: 2 / 3;
    grid-row: span 2;
  }
`;

const Image2 = styled(Image)`
  @media (${media.l}) {
    clip-path: polygon(
      100% 0,
      100% calc(100% - var(--cropSize)),
      calc(100% - var(--cropSize)) 100%,
      0 100%,
      0 0
    );
  }
`;

const Image3Area = styled.div`
  display: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.l}) {
    display: block;
    height: 200px;
  }
`;

const Image3 = styled(Image)`
  @media (${media.l}) {
    clip-path: polygon(
      100% 0,
      100% calc(100% - calc(var(--cropSize) * 0.7)),
      calc(100% - calc(var(--cropSize) * 0.7)) 100%,
      0 100%,
      0 0
    );
  }
`;

const Image4Area = styled.div`
  display: none;

  @media (${media.m}) {
    display: block;
    grid-column: 1 / 2;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);
  }

  @media (${media.l}) {
    height: 300px;
    grid-column: 1 / 3;
  }
`;

const Image4 = styled(Image)`
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    var(--cropSize) 100%,
    0 calc(100% - var(--cropSize))
  );
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  text-transform: none;
  line-height: 1em;
  font-size: 1.2em;
  font-weight: 300;
`;

const ContactList = styled(NavMenu)`
  flex-direction: column;
  align-items: flex-start !important;
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1em !important;
  margin-right: 10px;
`;

const dialogStyles = {
  style: {
    borderRadius: 0,
  },
};

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const formSetup = {
  initialValues: {
    name: '',
    email: '',
    tel: '',
    subject: '',
    message: '',
  },
  formLayout: {
    name: {
      label: 'Imię i Nazwisko',
      type: 'text',
    },
    email: {
      label: 'Adres e-mail',
      type: 'email',
      required: true,
    },
    tel: {
      label: 'Telefon',
      type: 'text',
    },
    subject: {
      label: 'Temat',
      type: 'text',
      required: true,
    },
    message: {
      label: 'Treść wiadomości',
      type: 'textarea',
      required: true,
      props: {
        multiline: true,
        rows: '6',
      },
    },
  },
  validationSchema: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email('Niepoprawny e-mail')
      .required('Wymagane'),
    tel: Yup.string(),
    subject: Yup.string().required('Wymagane'),
    message: Yup.string().required('Wymagane'),
  }),
};

const ContactPage = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);

  const sendMessage = async data => {
    const { name, email, subject, tel, message } = data;

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'Kontakt',
          name,
          email,
          subject,
          tel,
          message,
        }),
      });

      if (window.dataLayer) {
        // eslint-disable-next-line no-undef
        window.dataLayer.push({
          event: 'conversion',
        });
      }

      setShowDialog(true);
    } catch (error) {
      setError(true);
    }
  };

  const {
    prismicStronaKontakt: {
      data: {
        baner,
        tytul_sekcji,
        naglowek_sekcji,
        nazwa_firmy,
        adres_firmy,
        dane_ksiegowe,
        sekretariat: sekretariatArr,
        kontakt_telefoniczny: kontaktTelefonicznyArr,
        dzial_handlowy: dzialHandlowyArr,
        zdjecie_1,
        zdjecie_2,
        zdjecie_3,
        zdjecie_4,
        body_meta,
      },
    },
  } = data;

  const [sekretariat] = sekretariatArr;

  const [kontakt_telefoniczny] = kontaktTelefonicznyArr;

  const [dzial_handlowy] = dzialHandlowyArr;

  const seoData = body_meta && body_meta.find(el => el.slice_type === 'seo');

  return (
    <>
      <SEO
        title={seoData.primary.tytul}
        description={seoData.primary.opis}
        url="/kontakt/"
      />

      <Banner
        title="Kontakt"
        image={{ alt: baner.alt, image: baner.localFile.childImageSharp.fluid }}
      />

      <Container>
        <AlertBanner>
          <Typography variant="p" className="h3">
            Od marca 2020 roku zmieniamy adres magazynów
          </Typography>

          <Typography>
            Zapraszamy pod adres: <strong>{adres_firmy.text}</strong>
          </Typography>
        </AlertBanner>

        <Grid>
          <Image1Area data-aos="fade-down" data-aos-delay="200">
            <Image1
              fluid={zdjecie_1.localFile.childImageSharp.fluid}
              alt={zdjecie_1.alt}
            />
          </Image1Area>

          <Image2Area data-aos="fade-left" data-aos-delay="300">
            <Image2
              fluid={zdjecie_2.localFile.childImageSharp.fluid}
              alt={zdjecie_2.alt}
            />
          </Image2Area>

          <Image3Area data-aos="fade-right" data-aos-delay="300">
            <Image3
              fluid={zdjecie_3.localFile.childImageSharp.fluid}
              alt={zdjecie_3.alt}
            />
          </Image3Area>

          <Image4Area data-aos="fade-up" data-aos-delay="300">
            <Image4
              fluid={zdjecie_4.localFile.childImageSharp.fluid}
              alt={zdjecie_4.alt}
            />
          </Image4Area>

          <Subgrid>
            <HeadingArea data-aos="fade-up" data-aos-delay="200">
              <Heading
                title={tytul_sekcji.text}
                heading={naglowek_sekcji.html}
              />
            </HeadingArea>

            <AddressArea data-aos="fade-up" data-aos-delay="200">
              <Typography variant="h4" css="margin-bottom: 1em;">
                {nazwa_firmy}
              </Typography>

              <div dangerouslySetInnerHTML={{ __html: adres_firmy.html }} />

              <div dangerouslySetInnerHTML={{ __html: dane_ksiegowe.html }} />
            </AddressArea>

            <SecretaryArea data-aos="fade-up" data-aos-delay="200">
              <Typography variant="h4" css="margin-bottom: 1em;">
                Sekretariat
              </Typography>

              <ContactList>
                <NavItem>
                  <ContactLink href={`tel: ${sekretariat.telefon_1}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {sekretariat.telefon_1}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`tel: ${sekretariat.fax}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    Fax {sekretariat.fax}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`tel: ${sekretariat.telefon_2}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {sekretariat.telefon_2}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`tel: ${sekretariat.telefon_3}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {sekretariat.telefon_3}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`mailto: ${sekretariat.email}`}>
                    <Icon>
                      <MailIcon />
                    </Icon>
                    {sekretariat.email}
                  </ContactLink>
                </NavItem>
              </ContactList>
            </SecretaryArea>

            <PhoneArea data-aos="fade-up" data-aos-delay="200">
              <Typography variant="h4" css="margin-bottom: 1em;">
                Kontakt telefoniczny
              </Typography>

              <ContactList>
                <NavItem>
                  <ContactLink href={`tel: ${kontakt_telefoniczny.telefon_1}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {kontakt_telefoniczny.telefon_1}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`tel: ${kontakt_telefoniczny.telefon_2}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {kontakt_telefoniczny.telefon_2}
                  </ContactLink>
                </NavItem>
              </ContactList>
            </PhoneArea>

            <CommerceArea data-aos="fade-up" data-aos-delay="200">
              <Typography variant="h4" css="margin-bottom: 1em;">
                Dział handlowy
              </Typography>

              <ContactList>
                <NavItem>
                  <ContactLink href={`tel: ${dzial_handlowy.telefon_1}`}>
                    <Icon>
                      <PhoneIcon />
                    </Icon>
                    {dzial_handlowy.telefon_1}
                  </ContactLink>
                </NavItem>

                <NavItem>
                  <ContactLink href={`mailto: ${dzial_handlowy.email}`}>
                    <Icon>
                      <MailIcon />
                    </Icon>
                    {dzial_handlowy.email}
                  </ContactLink>
                </NavItem>
              </ContactList>
            </CommerceArea>

            <FormArea data-aos="fade-up" data-aos-delay="300">
              <Typography
                variant="h3"
                css="font-weight: 300; margin-bottom: 1em;"
              >
                Formularz kontaktowy
              </Typography>

              <ContactForm
                initialValues={formSetup.initialValues}
                formLayout={formSetup.formLayout}
                validationSchema={formSetup.validationSchema}
                onSubmit={sendMessage}
              />
            </FormArea>
          </Subgrid>
        </Grid>

        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          PaperProps={dialogStyles}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">Wiadomość została wysłana</Typography>
          </DialogTitle>

          <DialogContent>
            <Typography variant="h4">Dziękujemy za kontakt</Typography>
          </DialogContent>

          <DialogActions css="margin-top: 15px;">
            <Button onClick={() => setShowDialog(false)}>Zamknij okno</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={error}
          onClose={() => setError(false)}
          PaperProps={dialogStyles}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">
              Nie udało się wysłać wiadomości
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography variant="h4">
              Spróbuj jeszcze raz lub napisz na{' '}
              <a href={`mailto: ${sekretariat.email}`}>{sekretariat.email}</a>
            </Typography>
          </DialogContent>

          <DialogActions css="margin-top: 15px;">
            <Button onClick={() => setError(false)}>Zamknij okno</Button>
          </DialogActions>
        </Dialog>
      </Container>

      <Footer showMap />
    </>
  );
};

export const query = graphql`
  query {
    prismicStronaKontakt {
      data {
        baner {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji {
          text
        }
        naglowek_sekcji {
          html
        }
        nazwa_firmy
        adres_firmy {
          text
          html
        }
        dane_ksiegowe {
          html
        }
        sekretariat {
          telefon_1
          fax
          telefon_2
          telefon_3
          email
        }
        kontakt_telefoniczny {
          telefon_1
          telefon_2
        }
        dzial_handlowy {
          telefon_1
          email
        }
        zdjecie_1 {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_2 {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_3 {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_4 {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body_meta {
          slice_type
          primary {
            tytul
            opis
          }
        }
      }
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;
